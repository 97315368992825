import { initializeApp } from "firebase/app";
import { doc, getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "../const/firebase-config";

const firebaseApp = initializeApp(firebaseConfig);
const dbFirestore = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LfOyRAqAAAAAJ7bVZqIKwoxfkPuFqH004PvknWC"),
  isTokenAutoRefreshEnabled: true
});

const pathCollPaypalTransactions = "paypal-transactions";
const pathCollNewsletterSubscription = "newsletter-subscriptions";

const refTicketsData = doc(dbFirestore, "app-data", "tickets-data");
const refMasterclassData = doc(dbFirestore, "app-data", "masterclass-data");
const refTokenData = doc(dbFirestore, "app-data", "token-data");

export { pathCollPaypalTransactions, refTicketsData, refMasterclassData, refTokenData, dbFirestore, analytics, pathCollNewsletterSubscription };
