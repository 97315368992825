import LanguageContextProvider from "./context/LanguageContext/LanguageContextProvider";
import {Biglietteria} from "./components/Biglietteria/Biglietteria";

function App() {
    return (
        <LanguageContextProvider>
            <Biglietteria/>
        </LanguageContextProvider>
    );
}

export default App;
