const PAYPAL_CURRENCY_CODE = "EUR";
const PAYPAL_CLIENT_ID_SANDBOX =
    "AVFi1Zd1MrEcizWoa2jVcNeo59zBLEe6ZpRDMp3OJxC9Wh_b87yZEpw8morOp7A3Qlu4-RWOKGEh9otn";
const PAYPAL_CLIENT_ID_LIVE =
    "AV-VYOoDD1ipmSiRN_VgYFetcnVTLoxlYtwggB9iTEJye-8xxx9lA5bfX1eO4tZDQap5rqYgNpT6i0wI";

//todo Trasformare in data
const LAST_DAY_TO_BUY = "06/08/2024";

const EVENT_DATETIME = new Date("2024-08-06 19:00:00");

const EVENT_DATE_STRING = (locale) => {
    return new Intl.DateTimeFormat(locale, {
        dateStyle: "full",
        timeStyle: "short"
    }).format(EVENT_DATETIME);
};

const STEP_CARDS = 0;
const STEP_DATA_FORM = 1;
const STEP_TERMS_CONDITIONS = 2;
const STEP_PAYMENT = 3;
const STEP_CONFIRMED = 4;
const STEP_CANCELLED = 5;
const STEP_ERROR = 6;

const PRODUCT_TICKETS = 1;
const PRODUCT_MASTERCLASS = 2;
const PRODUCT_TOKEN = 3;

export {
    PAYPAL_CURRENCY_CODE,
    PAYPAL_CLIENT_ID_LIVE,
    PAYPAL_CLIENT_ID_SANDBOX,
    STEP_CARDS,
    STEP_DATA_FORM,
    STEP_TERMS_CONDITIONS,
    STEP_PAYMENT,
    STEP_CONFIRMED,
    STEP_CANCELLED,
    STEP_ERROR,
    LAST_DAY_TO_BUY,
    EVENT_DATETIME,
    PRODUCT_TICKETS,
    PRODUCT_MASTERCLASS,
    PRODUCT_TOKEN,
    EVENT_DATE_STRING
};
