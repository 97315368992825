import React from "react";
import LanguageContext from "./LanguageContext";

export default function LanguageContextProvider({ children }) {
  const userLang = navigator.language || navigator.userLanguage;

  const userLangSplit = userLang.split("-");

  const initialUserLang = userLangSplit[0] === "en" ? "en" : "it";

  const [lang, setLang] = React.useState(initialUserLang);

  const values = {
    lang,
    setLang
  };

  React.useEffect(() => {
    document.documentElement.setAttribute("lang", lang);
  }, [lang]);

  return (
    <LanguageContext.Provider value={values}>
      {children}
    </LanguageContext.Provider>
  );
}
