import {doc, setDoc, getDoc} from "firebase/firestore";
import {
    dbFirestore,
    pathCollNewsletterSubscription,
} from "../../FirebaseProvider/FirebaseProvider";
import {useState} from "react";

export const NewsLetter = () => {

    const [subscribed, setSubscribed] = useState(false);
    const [alredySubscribed, setAlredySubscribed] = useState(false);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const mail = formData.get("email");
        if (!mail.trim()) {
            return;
        }

        const refDoc = doc(dbFirestore, pathCollNewsletterSubscription, mail);

        const docSnap = await getDoc(refDoc, {
            subscribedAt: new Date(Date.now()),
        });

        if (docSnap.exists()) {
            setSubscribed(true);
            setAlredySubscribed(true);
            return;
        }

        await setDoc(refDoc, {
            subscribedAt: new Date(Date.now()),
            source: 'newsletter-form'
        });

        setSubscribed(true);
        setAlredySubscribed(false);
    }
    return (
        <div className="footer-widget-box about-us">
            <a href="#" className="footer-logo">
                <img src="assets/img/logo/logo-light.svg" alt="Wine Not?"/>
            </a>
            <p className="mb-3">
                Lascia qui la tua mail per non perdere i dettagli delle prossime edizioni.
            </p>
            <div className="footer-newsletter">
                {subscribed
                    ?
                    <h3 className={"text-white"}> {alredySubscribed ? `e-Mail già registrata!` : `Grazie per la tua iscrizione!`}</h3>
                    :
                    <><p>Iscriviti alla Newsletter</p>
                        <div className="subscribe-form">

                            <form action="#" onSubmit={handleOnSubmit}>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control" placeholder="La tua email"/>
                                    <button className="theme-btn" type="submit">
                                        <span className="far fa-paper-plane"></span> Iscriviti
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
