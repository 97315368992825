import {Component, Suspense} from "react";

export const withSuspense = (Children) => {
    return class extends Component {
        render() {
            return (
                <Suspense fallback={<Loading/>}>
                    <Children {...this.props} />
                </Suspense>
            );
        }
    };
};

const Loading = () => {
    return <div>Loading...</div>
}