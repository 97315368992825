import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {withSuspense} from "./hooks/withSuspense";
import {NewsLetter} from "./components/NewsLetter/NewsLetter";

const rootBiglietteria = ReactDOM.createRoot(document.getElementById("react-biglietteria"));
const rootNewsletter = ReactDOM.createRoot(document.getElementById("react-newsletter"));
const WSApp = withSuspense(App);
const WSNewsLetter = withSuspense(NewsLetter);

rootBiglietteria.render(
  <React.StrictMode>
    <WSApp />
  </React.StrictMode>
);

rootNewsletter.render(
  <React.StrictMode>
    <WSNewsLetter />
  </React.StrictMode>
);
