import React from "react";

export default function BigliettiTerminati({ where, lang }) {
  switch (where) {
    case "paypal-wrapper":
      return <NoTicketsAreaPayPal lang={lang} />;
    case "sell-form":
    default:
      return <NoTicketsAreaForm lang={lang} />;
  }
}

const NoTicketsAreaForm = ({ lang }) => {
  return (
    <div className="sold-out">
      <h2 className="text-center">
        {lang === "en"
          ? "Online Tickets Sold Out!"
          : "Biglietti Online Terminati!"}
      </h2>
      <h3 className="text-center uppercase">
        {lang === "en"
          ? `If still available, it will be possibile to buy tickets at the
            cashier's desks.`
          : `Salvo disponibilità, potrai acquistarli alle casse il giorno
            dell'evento.`}
      </h3>
    </div>
  );
};
const NoTicketsAreaPayPal = ({ lang }) => {
  return (
    <div className="sold-out">
      <h2 className="site-title-tagline text-center">
        {lang === "en"
          ? "Online Tickets Sold Out!"
          : "Biglietti Online Terminati!"}
      </h2>
      <h3 className="text-center uppercase">
        {lang === "en"
          ? `If still available, it will be possibile to buy tickets at the
            cashier's desks.`
          : `Salvo disponibilità, potrai acquistarli alle casse il giorno
            dell'evento.`}
      </h3>
      <p className="text-center italic">
        {lang === "en"
          ? "If the payment has been successful, you will still receive your tickets."
          : "Se il versamento è andato a buon fine riceverai comunque i tuoi biglietti."}
        <br />
        {lang === "en" ? "We are waiting for you!" : "Ti aspettiamo"}
      </p>
    </div>
  );
};
