import React from "react";
import {PRODUCT_TOKEN, STEP_DATA_FORM} from "../../const/const";
import useLanguageContext from "../../context/LanguageContext/useLanguageContext";

const ConfermaOrdine = ({data, onReturn}) => {
    const {lang} = useLanguageContext();

    switch (lang) {
        case "en":
            return (
                <ENConfermaOrdine
                    data={data}
                    onReturn={onReturn}
                />
            );
        default:
            return (
                <ITConfermaOrdine
                    data={data}
                    onReturn={onReturn}
                />
            );
    }
};

const ENConfermaOrdine = ({data, onReturn}) => {
    return (
        <div>
            <h2 className="text-center">Your order has been succesful!</h2>
            <h3 className="text-center  mt-3 text-bg-theme-color">We are waiting for you.</h3>
            <p className="mt-3 text-center">
                You will receive
                the {data.product === PRODUCT_TOKEN ? "Token" : "Ticket"}{data.qty === 1 ? "" : "s"} to{" "}
                <i>{data.email}</i>.
                <br/>
                For any information or problems, use our contacts.
            </p>

            <div className="mt-5 text-center">
                <button
                    type="button"
                    className="btn btn-secondary inverse"
                    onClick={() => {
                        onReturn(STEP_DATA_FORM, data);
                    }}
                >
                    New purchase?
                </button>
            </div>
        </div>
    );
};

const ITConfermaOrdine = ({data, onReturn}) => {

    return (
        <div>
            <h2 className="text-center">L'ordine è andato a buon fine!</h2>
            <h3 className="text-center mt-3 text-bg-theme-color">
                {data.qty === 1 ? "Ti" : "Vi"} aspettiamo a Wine Not? 2024.
            </h3>
            <p className="mt-3 text-center">
                Riceverai {data.qty === 1 ? "il" : "i"} {data.product === PRODUCT_TOKEN ? "Token" : "Ticket"} da ritirare alle casse il giorno dell'evento, all'indirizzo <i>{data.email}</i>.
                <br/>
                Per qualsiasi informazione o problema, utilizza il nostro canale di contatto.
            </p>

            <div className="mt-5 text-center">
                <button
                    type="button"
                    className="theme-btn"
                    onClick={() => {
                        onReturn(STEP_DATA_FORM, data);
                    }}
                >
                    Nuovo acquisto?
                </button>
            </div>
        </div>
    );
};

export default ConfermaOrdine;
