const firebaseConfig = {
  apiKey: "AIzaSyAYdWgQtX7JRKdJLsFzbOZuQVIUUn2j9-4",
  authDomain: "winenot-2024.firebaseapp.com",
  projectId: "winenot-2024",
  storageBucket: "winenot-2024.appspot.com",
  messagingSenderId: "34564230892",
  appId: "1:34564230892:web:fc010ee654b8cfa3079fc9",
  measurementId: "G-64B9WP2BSD"
};

export default firebaseConfig;
