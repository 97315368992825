import React from "react";
import {
    PRODUCT_MASTERCLASS,
    PRODUCT_TICKETS,
    PRODUCT_TOKEN,
    STEP_CARDS,
    STEP_TERMS_CONDITIONS
} from "../../const/const";
import {useDocument} from "react-firebase-hooks/firestore";
import {refMasterclassData, refTicketsData, refTokenData} from "../../FirebaseProvider/FirebaseProvider";
import BigliettiTerminati from "./BigliettiTerminati";
import useLanguageContext from "../../context/LanguageContext/useLanguageContext";

const INPUTS_ERROR_STATE = {
    nominativo: false,
    email: false,
    telefono: false,
    product: null,
    qty: false
};

export default function SellForm({data, goToStep}) {
    const {lang} = useLanguageContext();

    const [inputsValues, updateInputsValues] = React.useState(data);
    const [inputsErrorState, updateInputsErrorValues] =
        React.useState(INPUTS_ERROR_STATE);

    const [totalAmount, setTotalAmount] = React.useState(0);
    const [ticketPrice, setTicketPrice] = React.useState(0);
    const [ticketsCount, setTicketsCount] = React.useState(0);

    let whichRef;

    switch (data.product) {
        case PRODUCT_TICKETS:
            whichRef = refTicketsData;
            break;
        case PRODUCT_MASTERCLASS:
            whichRef = refMasterclassData;
            break;
        case PRODUCT_TOKEN:
            whichRef = refTokenData;
            break;
        default:
            whichRef = null;
    }

    const [snapshot, loading, error] = useDocument(whichRef);

    const handleInputsChange = (field, value) => {
        updateInputsValues({...inputsValues, [field]: value});
    };

    const isEmpty = (str) => {
        return !!!str.trim().length;
    };

    const validateEmail = (email) => {
        if (!email) return false;
        const validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return String(email).match(validRegex);
    };

    const validateInputs = () => {
        updateInputsErrorValues({
            nominativo: isEmpty(inputsValues.nominativo),
            email: isEmpty(inputsValues.email) || !validateEmail(inputsValues.email),
            telefono: isEmpty(inputsValues.telefono),
            qty: inputsValues.qty < 1
        });

        const hasError =
            isEmpty(inputsValues.nominativo) ||
            isEmpty(inputsValues.email) ||
            !validateEmail(inputsValues.email) ||
            isEmpty(inputsValues.telefono) ||
            inputsValues.qty < 1;

        return !hasError;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateInputs()) {
            goToStep(STEP_TERMS_CONDITIONS, {...inputsValues, totalAmount});
        }
    };

    React.useEffect(() => {
        if (!snapshot) return;
        const ticketsData = snapshot.data();

        if (!ticketsData.TICKETS_PRICE) return;

        setTicketPrice(ticketsData.TICKETS_PRICE);
        setTicketsCount(ticketsData.TICKETS_LEFT_ONLINE);
    }, [snapshot]);

    React.useEffect(() => {
        setTotalAmount(inputsValues.qty * ticketPrice);
    }, [inputsValues.qty, ticketPrice]);

    React.useEffect(() => {
        updateInputsValues(data);
    }, [data]);

    if (ticketsCount <= 0) {
        return (
            <BigliettiTerminati
                where={"sell-form"}
                lang={lang}
            />
        );
    }

    return (
        <div className="row g-4">
            <div className={"col-sm-12 col-md-8 offset-md-2"}>
                <div>
                    <p>
                        {lang === "en"
                            ? "You will avoid the queue thanks to fast track"
                            : "Eviterai la fila grazie ad una corsia preferenziale"}
                    </p>
                </div>
                <form
                    className="ticket-form"
                    onSubmit={onSubmit}
                    noValidate
                >
                    <div className="mb-3">
                        <label className="form-label" htmlFor="nominativo">
                            {lang === "en" ? "Name and surname" : "Nominativo"}
                        </label>
                        <input
                            className={`form-control ${inputsErrorState.nominativo ? "is-invalid" : ""}`}
                            type="text"
                            placeholder={lang === "en" ? "John Doe" : "Mario Rossi"}
                            id="nominativo"
                            required
                            value={inputsValues.nominativo}
                            onChange={(e) => {
                                handleInputsChange("nominativo", e.target.value);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                            {lang === "en" ? "Email address" : "Email"}
                        </label>
                        <input
                            className={`form-control ${inputsErrorState.email ? "is-invalid" : ""}`}
                            type="email"
                            id="email"
                            placeholder="Email"
                            required
                            autoComplete="on"
                            value={inputsValues.email}
                            onChange={(e) => {
                                handleInputsChange("email", e.target.value.trim().toLowerCase());
                            }}
                        />
                        <small>
                            {lang === "en"
                                ? "Check your email address. You will receive your tickets there."
                                : "Verifica la correttezza della mail. È qui che invieremo i biglietti prenotati!"}
                        </small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="telefono">
                            {lang === "en" ? "Phone number" : "Telefono"}
                        </label>
                        <input
                            className={`form-control ${inputsErrorState.telefono ? "is-invalid" : ""}`}
                            type="tel"
                            id="telefono"
                            placeholder="Telefono"
                            required
                            value={inputsValues.telefono}
                            onChange={(e) => {
                                handleInputsChange("telefono", e.target.value.trim());
                            }}
                        />
                        <small>
                            {lang === "en"
                                ? "We may need to contact you."
                                : "Potremmo avere bisogno di contattarti."}
                        </small>
                    </div>
                    <div className="input-qty-tickets">
                        <label className="form-label" htmlFor="qty">{lang === "en" ? "Quantity" : "Quantità"}</label>
                        <SelectQty
                            lang={lang}
                            product={data.product}
                            inputsErrorState={inputsErrorState}
                            handleInputsChange={handleInputsChange}
                            inputsValues={inputsValues}/>
                        <span className="qty-preview">
            {loading ? (
                "..."
            ) : (
                <div className={"lead mt-4"}>
                    {inputsValues.qty} x {ticketPrice} = {totalAmount} €
                </div>
            )}
          </span>
                    </div>

                    <div className="d-flex flex-justify-center justify-content-between mt-4">
                        <button
                            disabled={error || ticketPrice === 0 || loading}
                            type="button"
                            className="theme-btn"
                            onClick={() => {
                                goToStep(STEP_CARDS, data);
                            }}
                        >
                            {lang === "en" ? "Back" : "Indietro"}
                        </button>
                        <button
                            disabled={error || ticketPrice === 0 || loading}
                            type="submit"
                            className="theme-btn"
                        >
                            {lang === "en" ? "Next" : "Avanti"}
                        </button>
                    </div>
                </form>
                <br/>
                <p className="text-center">
                    {lang === "en" ? (
                        <small>
                            You will pay via{" "}
                            <a
                                href="https://www.paypal.com"
                                target={"_blank"}
                                rel="noreferrer"
                                title="PayPal"
                            >
                                PayPal
                            </a>{" "}
                            service.
                        </small>
                    ) : (
                        <small>
                            Pagherai in tutta serenità utilizzando il servizio{" "}
                            <a
                                href="https://www.paypal.com"
                                target={"_blank"}
                                rel="noreferrer"
                                title="PayPal"
                            >
                                PayPal.
                            </a>
                        </small>
                    )}
                </p>
            </div>
        </div>
    );
}


const SelectQty = ({lang, product, inputsErrorState, handleInputsChange, inputsValues}) => {
    if (product === PRODUCT_TOKEN) {
        return (
            <select
                className={`form-select ${inputsErrorState.qty ? "is-invalid" : ""}`}
                id="qty"
                onChange={(e) => {
                    handleInputsChange("qty", Number(e.target.value));
                }}
                value={inputsValues.qty}
                required
            >
                <option
                    value={0}
                    disabled
                >
                    {lang === "en"
                        ? "Choose a purchase option"
                        : "Seleziona un opzione di acquisto"}
                </option>
                <option value={5}>
                    5 Token
                </option>
                <option value={10}>
                    10 Token
                </option>
                <option value={15}>
                    15 Token
                </option>
                <option value={20}>
                    20 Token
                </option>
                <option value={25}>
                    25 Token
                </option>
                <option value={50}>
                    50 Token
                </option>
                <option value={100}>
                    100 Token
                </option>
            </select>
        )
    }
    return (
        <select
            className={`form-select ${inputsErrorState.qty ? "is-invalid" : ""}`}
            id="qty"
            onChange={(e) => {
                handleInputsChange("qty", Number(e.target.value));
            }}
            value={inputsValues.qty}
            required
        >
            <option
                value={0}
                disabled
            >
                {lang === "en"
                    ? "Choose a purchase option"
                    : "Seleziona un opzione di acquisto"}
            </option>
            <option value={1}>
                1 {lang === "en" ? "Ticket" : "Biglietto"}
            </option>
            <option value={2}>
                2 {lang === "en" ? "Tickets" : "Biglietti"}
            </option>
            <option value={3}>
                3 {lang === "en" ? "Tickets" : "Biglietti"}
            </option>
            <option value={4}>
                4 {lang === "en" ? "Tickets" : "Biglietti"}
            </option>
            <option value={5}>
                5 {lang === "en" ? "Tickets" : "Biglietti"}
            </option>
        </select>
    )
}
