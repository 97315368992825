import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import React from "react";
import {
    analytics,
    pathCollPaypalTransactions,
    dbFirestore,
    refTicketsData, refMasterclassData, refTokenData
} from "../../FirebaseProvider/FirebaseProvider";
import {useDocument} from "react-firebase-hooks/firestore";
import {
    PAYPAL_CURRENCY_CODE,
    STEP_CANCELLED,
    STEP_CONFIRMED,
    STEP_ERROR,
    PRODUCT_TICKETS, PRODUCT_MASTERCLASS, PRODUCT_TOKEN, STEP_CARDS
} from "../../const/const";

import {doc, setDoc} from "firebase/firestore";
import {logEvent} from "firebase/analytics";
import BigliettiTerminati from "./BigliettiTerminati";
import useLanguageContext from "../../context/LanguageContext/useLanguageContext";

const buildItemsForAnalytics = (qty, ticketPrice) => {
    let items = [];
    for (let i = 1; i <= qty; i++) {
        items.push({
            item_id: "biglietto-winenot",
            item_name: "Biglietto Wine Not",
            quantity: 1,
            price: ticketPrice
        });
    }
    return items;
};

export default function PaypalButtonsWrapper({
                                                 data,
                                                 onReturn,
                                                 onSuccess,
                                                 onError,
                                                 onCancel
                                             }) {
    const [{isPending, isRejected, isResolved}] = usePayPalScriptReducer();

    const [ticketPrice, setTicketPrice] = React.useState(0);
    const [ticketsCount, setTicketsCount] = React.useState(0);

    let whichRef;

    let ppOrderDescription, ppOrderSoft_descriptor, ppOrderItemName, ppOrderItemDescription;

    switch (data.product) {
        case PRODUCT_TICKETS:
            whichRef = refTicketsData;
            ppOrderDescription = "Biglietti Wine Not? 2024";
            ppOrderSoft_descriptor = "WineNot? 2024";
            ppOrderItemName = "Biglietto Degustazione";
            ppOrderItemDescription = "Biglietto Degustazione Wine Not? 2024";
            break;
        case PRODUCT_MASTERCLASS:
            whichRef = refMasterclassData;
            ppOrderDescription = "Biglietti Masterclass Wine Not? 2024";
            ppOrderSoft_descriptor = "WineNot? Masterclass";
            ppOrderItemName = "Biglietto Masterclass";
            ppOrderItemDescription = "Biglietto Masterclass Wine Not? 2024";
            break;
        case PRODUCT_TOKEN:
            whichRef = refTokenData;
            ppOrderDescription = "Token Wine Not? 2024";
            ppOrderSoft_descriptor = "WineNot? Token";
            ppOrderItemName = "Token";
            ppOrderItemDescription = "Token Wine Not? 2024";
            break;
        default:
            whichRef = null;
    }

    const [snapshot, loading, error] = useDocument(whichRef);

    const {lang} = useLanguageContext();

    const errorOnOrderCreation =
        lang === "en"
            ? `Problems during the order creation.<br/>If the error persist, please use our contacts.`
            : `Problemi durante la creazione dell'ordine.<br/>Se l'errore persiste usa il nostro canale di contatto.`;
    const errorOnError =
        lang === "en"
            ? `We are experiencing problems with the payments provider.<br/>If the error persist, please use our contacts.`
            : `Problemi con il provider dei versamenti.<br/>Se l'errore persiste usa il nostro canale di contatto.`;
    const errorOnOrderApprove =
        lang === "en"
            ? `Problems during the order approvation.<br/>If the error persist, please use our contacts.`
            : `Problemi durante l'approvazione dell'ordine.<br/>Se l'errore persiste usa il nostro canale di contatto.`;


    const saveTransaction = async (details) => {
        try {
            await setDoc(doc(dbFirestore, pathCollPaypalTransactions, details.id), {
                winenotData: data,
                lang,
                ...details
            });

            logEvent(analytics, "purchase", {
                transaction_id: details.id,
                currency: PAYPAL_CURRENCY_CODE,
                value: data.qty * ticketPrice,
                items: buildItemsForAnalytics(data.qty, ticketPrice)
            });

            onSuccess(STEP_CONFIRMED, data, {winenotData: data, ...details});
        } catch (err) {
            logEvent(analytics, "error_save_transaction", err);
            onError(STEP_ERROR, data, `${errorOnError} ID ${details.id}`);
        }
    };

    React.useEffect(() => {
        if (!snapshot) return;
        const ticketsData = snapshot.data();

        if (!ticketsData.TICKETS_PRICE) return;

        setTicketPrice(ticketsData.TICKETS_PRICE);
        setTicketsCount(ticketsData.TICKETS_LEFT_ONLINE);
    }, [snapshot]);

    const toFloatString = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2).toString();
    };

    if (error || isRejected) {
        return (
            <div className="row g-4">
                <div className={"col-sm-12 col-md-8 offset-md-2"}>
                    <div className="paypal-wrapper bg-white">
                        <h3 className="text-bg-theme-color">Problemi</h3>
                        <p className="text-bg-theme-color">
                            {lang === "en" ? (
                                <>We are experiencing problems with the Payment Provider.</>
                            ) : (
                                <>Stiamo riscontrando dei problemi con il Provider dei Versamenti.</>
                            )}
                            <br/>
                            {lang === "en" ? (
                                <>If the problem persist, please use our contacts.</>
                            ) : (
                                <>Se il problema persiste, usa il nostro canale di contatto.</>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (loading || isPending) {
        return (
            <div className="row g-4">
                <div className={"col-sm-12 col-md-8 offset-md-2"}>
                    <div className="paypal-wrapper bg-white">
                        <h4 className="text-bg-theme-color">
                            {lang === "en" ? "Loading" : "Caricamento in corso"}...
                        </h4>
                    </div>
                </div>
            </div>
        );
    }

    if (ticketsCount <= 0 || ticketPrice === 0) {
        return (
            <BigliettiTerminati
                where={"paypal-wrapper"}
                lang={lang}
            />
        );
    }

    const ORDER = {
        purchase_units: [
            {
                description: ppOrderDescription,
                soft_descriptor: ppOrderSoft_descriptor,
                items: [
                    {
                        name: ppOrderItemName,
                        quantity: data.qty,
                        description: ppOrderItemDescription,
                        unit_amount: {
                            currency_code: PAYPAL_CURRENCY_CODE,
                            value: toFloatString(ticketPrice)
                        }
                    }
                ],
                amount: {
                    currency_code: PAYPAL_CURRENCY_CODE,
                    value: toFloatString(data.totalAmount),
                    breakdown: {
                        item_total: {
                            currency_code: PAYPAL_CURRENCY_CODE,
                            value: toFloatString(ticketPrice * data.qty)
                        }
                    }
                }
            }
        ],
        intent: "CAPTURE"
    };

    const onPaypalButtonCreateOrder = async (_data, actions) => {
        try {
            return await actions.order.create(ORDER);
        } catch (err) {
            console.error("onPaypalButtonCreateOrder", err);
            logEvent(analytics, "error_create_order", err);
            onError(STEP_ERROR, data, errorOnOrderCreation);
        }
    }

    const onPaypalButtonApprove = async (_data, actions) => {
        try {
            const details = await actions.order.capture();
            await saveTransaction(details);
        } catch (err) {
            logEvent(analytics, "error_on_approve", err);
            onError(
                STEP_ERROR,
                data,
                `${errorOnOrderApprove} ID ${_data.id}`
            );
        }
    }

    const onPaypalButtonError = (err) => {
        console.error("onPaypalButtonError", err);
        logEvent(analytics, "error_onerror_paypal_btn", err);
        onError(STEP_ERROR, data, errorOnError);
    }

    const onPaypalButtonCancel = (status) => {
        console.error("onPaypalButtonCancel", status);
        onCancel(STEP_CANCELLED, data, status);
        logEvent(analytics, "oncancel_paypal_btn", status);
    };


    if (isResolved) {
        return (
            <div className="row g-4">
                <div className={"col-sm-12 col-md-8 offset-md-2"}>
                    <div className="paypal-wrapper bg-white">
                        <div>
                            <PayPalButtons
                                style={{layout: "vertical"}}
                                forceReRender={[data]}
                                fundingSource={undefined}
                                createOrder={onPaypalButtonCreateOrder}
                                onApprove={onPaypalButtonApprove}
                                onError={onPaypalButtonError}
                                onCancel={onPaypalButtonCancel}
                            />
                        </div>
                    </div>
                    <div className="mt-6 text-center">
                        <button
                            type="button"
                            className="theme-btn"
                            onClick={() => {
                                onReturn(STEP_CARDS, data);
                            }}
                        >
                            {lang === "en" ? "Cancel" : "Annulla"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
