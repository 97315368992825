import React from "react";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {
    PAYPAL_CLIENT_ID_LIVE,
    PAYPAL_CURRENCY_CODE,
    PRODUCT_MASTERCLASS,
    PRODUCT_TICKETS,
    PRODUCT_TOKEN,
    STEP_CANCELLED,
    STEP_CARDS,
    STEP_CONFIRMED,
    STEP_DATA_FORM,
    STEP_ERROR,
    STEP_PAYMENT,
    STEP_TERMS_CONDITIONS
} from "../../const/const";
import SellForm from "./SellForm";
import TerminiCondizioni from "./TerminiCondizioni";
import PaypalButtonsWrapper from "./PaypalButtonsWrapper";
import ConfermaOrdine from "./ConfermaOrdine";
import OrdineAnnullato from "./OrdineAnnullato";
import OrdineErrore from "./OrdineErrore";
import useLanguageContext from "../../context/LanguageContext/useLanguageContext";
import {refMasterclassData, refTicketsData, refTokenData} from "../../FirebaseProvider/FirebaseProvider";
import {useDocument} from "react-firebase-hooks/firestore";

const PAYPAL_OPTS = {
    clientId: PAYPAL_CLIENT_ID_LIVE,
    components: "buttons",
    currency: PAYPAL_CURRENCY_CODE
};

const INPUTS_INITIAL_STATE = {
    nominativo: "",
    email: "",
    telefono: "",
    product: null,
    qty: 1
};

export function Biglietteria() {

    const {lang} = useLanguageContext();
    const [step, updateStep] = React.useState(0);
    const [data, setData] = React.useState(INPUTS_INITIAL_STATE);
    const [error, setError] = React.useState(null);
    const [details, setDetails] = React.useState(null);

    const goToStep = (_nextStep, _data, _details) => {
        updateStep(_nextStep);
        setData(_data);
        setDetails(_details);
    };

    const onError = (_step, _data, _error) => {
        updateStep(_step);
        setData(_data);
        setError(_error);
    };

    return (
        <div className="pricing-area py-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="site-heading text-center wow fadeInDown" data-wow-delay=".25s">
                            <span className="site-title-tagline">Biglietteria</span>
                            <h2 className="site-title">Non perdere <span>tempo</span> alle casse</h2>
                            <div className="heading-divider"></div>
                        </div>
                    </div>
                </div>
                <PayPalScriptProvider options={PAYPAL_OPTS}>
                    <StepWrapper
                        step={step}
                        data={data}
                        details={details}
                        error={error}
                        goToStep={goToStep}
                        onError={onError}
                    />
                </PayPalScriptProvider>
            </div>
        </div>
    )
}


const StepWrapper = ({data, step, error, details, goToStep, onError}) => {
    switch (step) {
        case STEP_CARDS:
            return (
                <Cards
                    data={data}
                    onTicketButtonClick={goToStep}
                />
            );
        case STEP_DATA_FORM:
            return (
                <SellForm
                    data={data}
                    goToStep={goToStep}
                />
            );
        case STEP_TERMS_CONDITIONS:
            return (
                <TerminiCondizioni
                    data={data}
                    onReturn={goToStep}
                    onConfirm={goToStep}
                />
            );

        case STEP_PAYMENT:
            return (
                <PaypalButtonsWrapper
                    data={data}
                    onReturn={goToStep}
                    onSuccess={goToStep}
                    onCancel={goToStep}
                    onError={onError}
                />
            );
        case STEP_CONFIRMED:
            return (
                <ConfermaOrdine
                    data={data}
                    onReturn={goToStep}
                />
            );
        case STEP_CANCELLED:
            return (
                <OrdineAnnullato
                    details={details}
                    data={data}
                    onReturn={goToStep}
                />
            );
        case STEP_ERROR:
            return (
                <OrdineErrore
                    data={data}
                    error={error}
                    onReturn={goToStep}
                />
            );
        default:
            return "";
    }
};

const Cards = ({onTicketButtonClick, data}) => {

    const [ticketPriceEvent, setTicketPriceEvent] = React.useState(0);
    const [ticketPriceMasterclass, setTicketPriceMasterclass] = React.useState(0);
    const [ticketPriceToken, setTicketPriceToken] = React.useState(0);

    const [ticketsCountEvent, setTicketsCountEvent] = React.useState(0);
    const [openToBuyEvent, setOpenToBuyEvent] = React.useState(false);
    const [ticketsCountMasterclass, setTicketsCountMasterclass] = React.useState(0);
    const [openToBuyMasterclass, setOpenToBuyMasterclass] = React.useState(false);
    const [ticketsCountToken, setTicketsCountToken] = React.useState(0);
    const [openToBuyToken, setOpenToBuyToken] = React.useState(false);

    const [snapEvent, loadingEvent, errorEvent] = useDocument(refTicketsData);
    const [snapMasterclass, loadingMasterclass, errorMasterclass] = useDocument(refMasterclassData);
    const [snapToken, loadingToken, errorToken] = useDocument(refTokenData);


    React.useEffect(() => {
        if (!snapEvent) return;
        const ticketsData = snapEvent.data();

        if (!ticketsData.TICKETS_PRICE) return;

        setTicketPriceEvent(ticketsData.TICKETS_PRICE);
        setTicketsCountEvent(ticketsData.TICKETS_LEFT_ONLINE);
        setOpenToBuyEvent(ticketsData.OPEN_TO_BUY);
    }, [snapEvent]);

    React.useEffect(() => {
        if (!snapMasterclass) return;
        const ticketsData = snapMasterclass.data();

        if (!ticketsData.TICKETS_PRICE) return;

        setTicketPriceMasterclass(ticketsData.TICKETS_PRICE);
        setTicketsCountMasterclass(ticketsData.TICKETS_LEFT_ONLINE);
        setOpenToBuyMasterclass(ticketsData.OPEN_TO_BUY);
    }, [snapMasterclass]);

    React.useEffect(() => {
        if (!snapToken) return;
        const ticketsData = snapToken.data();

        if (!ticketsData.TICKETS_PRICE) return;

        setTicketPriceToken(ticketsData.TICKETS_PRICE);
        setTicketsCountToken(ticketsData.TICKETS_LEFT_ONLINE);
        setOpenToBuyToken(ticketsData.OPEN_TO_BUY);
    }, [snapToken]);

    return (<div className="row g-4">

        <div className="col-md-6 col-lg-4">
            <div className={`pricing-item wow fadeInUp ${ticketsCountEvent <= 0 ? 'sold-out' : ''}`}
                 data-wow-delay=".25s">
                <div className="pricing-header-wrapper">
                    <div className="pricing-header">
                        <h5>Wine Not? 4 Agosto</h5>
                        <p className={"text-danger"}>
                            {ticketsCountEvent <= 0 ? 'Disponibilità online terminata!' : ''}
                        </p>
                    </div>
                    <p>
                        Prenota il tuo accesso all'Evento versando online la quota contributiva di
                    </p>
                    <div className="pricing-amount">
                        <strong>€{ticketPriceEvent}</strong><span className="pricing-amount-type">/persona</span>
                    </div>
                </div>
                <div className="pricing-btn-wrapper">
                    {openToBuyEvent ?
                        <button onClick={() => {
                            onTicketButtonClick(1, {...data, product: PRODUCT_TICKETS});
                        }}
                                className="theme-btn"
                                disabled={loadingEvent || errorEvent || (ticketsCountEvent <= 0)}
                        >{ticketsCountEvent <= 0 ? 'Sold Out' : 'Prenota Qui'}
                            <i className={loadingEvent ? "fas fa-spin fa-refresh" : "fas fa-arrow-right"}></i>
                        </button> :
                        <h4 className={"text-center"}>Prenotazione online non ancora disponibile</h4>
                    }
                </div>
                <div className="pricing-feature">
                    <ul>
                        <li><i className="fas fa-check-circle"></i>Ingresso Salta Fila</li>
                        <li><i className="fas fa-check-circle"></i>Calice Celebrativo Omaggio</li>
                        <li><i className="fas fa-check-circle"></i>Snack Kit Omaggio</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className={`pricing-item wow fadeInDown ${ticketsCountMasterclass <= 0 ? 'sold-out' : ''}`}
                 data-wow-delay=".25s">
                <div className="pricing-header-wrapper">
                    <div className="pricing-header">
                        <h5>Masterclass 3 Agosto @ 20.30</h5>
                        <p className={"text-danger"}>
                            {ticketsCountMasterclass <= 0 ? 'Disponibilità online terminata!' : ''}
                        </p>
                    </div>
                    <p>
                    Prenota il tuo accesso alla Masterclass versando online la quota contributiva di
                    </p>
                    <div className="pricing-amount">
                        <strong>€{ticketPriceMasterclass}</strong><span className="pricing-amount-type">/persona</span>
                    </div>
                </div>
                <div className="pricing-btn-wrapper">
                    {openToBuyMasterclass ?
                        <button onClick={() => {
                            onTicketButtonClick(1, {...data, product: PRODUCT_MASTERCLASS});
                        }}
                                className="theme-btn"
                                disabled={loadingMasterclass || errorMasterclass || (ticketsCountMasterclass <= 0)}
                        >{ticketsCountMasterclass <= 0 ? 'Sold Out' : 'Prenota Qui'}
                            <i className={loadingMasterclass ? "fas fa-spin fa-refresh" : "fas fa-arrow-right"}></i>
                        </button> :
                        <h4 className={"text-center"}>Prenotazione online non ancora disponibile</h4>
                    }
                </div>
                <div className="pricing-feature">
                    <ul>
                        <li><i className="fas fa-check-circle"></i>Posti Limitati</li>
                        <li><i className="fas fa-check-circle"></i>Calice Celebrativo Omaggio</li>
                        <li><i className="fas fa-check-circle"></i>Guidata da Sommelier AIS</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className={`pricing-item wow fadeInUp ${ticketsCountToken <= 0 ? 'sold-out' : ''}`}
                 data-wow-delay=".25s">
                <div className="pricing-header-wrapper">
                    <div className="pricing-header">
                        <h5>Token Area Food</h5>
                        <p className={"text-danger"}>
                            {ticketsCountToken <= 0 ? 'Disponibilità online terminata!' : ''}
                        </p>
                    </div>
                    <p>
                    Prenota online i token da ritirare in cassa per consumare in area food
                    </p>
                    <div className="pricing-amount">
                        <strong>€{ticketPriceToken}</strong><span className="pricing-amount-type">/Token</span>
                    </div>
                </div>
                <div className="pricing-btn-wrapper">
                    {openToBuyToken ?
                        <button onClick={() => {
                            onTicketButtonClick(1, {...data, product: PRODUCT_TOKEN, qty: 5});
                        }}
                                className="theme-btn"
                                disabled={loadingToken || errorToken || (ticketsCountToken <= 0)}
                        >{ticketsCountToken <= 0 ? 'Sold Out' : 'Prenota Qui'}
                            <i className={loadingToken ? "fas fa-spin fa-refresh" : "fas fa-arrow-right"}></i>
                        </button> :
                        <h4 className={"text-center"}>Prenotazione online non ancora disponibile</h4>
                    }
                </div>
                <div className="pricing-feature">
                    <ul>
                        <li><i className="fas fa-check-circle"></i>Minimo 5€ Online</li>
                        <li><i className="fas fa-check-circle"></i>Non Rimborsabili</li>
                        <li><i className="fas fa-check-circle"></i>Oltre 10 prodotti tra cui scegliere</li>
                    </ul>

                </div>
            </div>
        </div>
    </div>);
};