import React from "react";
import {PRODUCT_TOKEN, STEP_DATA_FORM, STEP_PAYMENT} from "../../const/const";

const TerminiCondizioni = ({data, onReturn, onConfirm}) => {
    return (
        <div className="row g-4">
            <div className={"col-sm-12 col-md-8 offset-md-2"}>
                <ITTerminiCondizioni
                    data={data}
                    onReturn={onReturn}
                    onConfirm={onConfirm}
                /></div>
        </div>
    );
};


const ITTerminiCondizioni = ({data, onReturn, onConfirm}) => {
    return (
        <div>
            <span className={"site-title-tagline text-center"}>Condizioni Generali di Partecipazione</span>
            <p>
                Gentile <i>{data.nominativo}</i>, ti preghiamo di prendere visione delle nostre condizioni generali di
                partecipazione e di verificare la correttezza dei dati inseriti.<br/>
                Potrai modificare le tue scelte cliccando sul tasto «Indietro» o proseguire con il versamento della
                quota contributiva attraverso il tasto «Conferma». La partecipazione costituisce accettazione delle
                Condizioni Generali di Partecipazione.<br/>

                <span className={"text-bg-theme-color"} style={{textDecoration: "underline", fontWeight: 600}}>
          La partecipazione costituisce accettazione delle Condizioni Generali di Partecipazione.</span>
            </p>

            <div style={{marginTop: "24px", maxHeight: "300px", overflow: "auto"}}>
                <span className={"site-title-tagline text-center"}>Definizioni</span>
                <h3 className={"py-2"}>
                    1. In queste Condizioni di Partecipazione i seguenti termini avranno il seguente significato:
                </h3>
                <ul>
                    <li>"Evento" si riferisce a Wine Not? 2024;</li>
                    <li>"Masterclass" si riferisce alla Masterclass di Degustazione organizzata in occasione di Wine
                        Not?;
                    </li>
                    <li>
                        "Accesso Evento" o "Ticket Evento" si riferisce a un permesso valido per l'accesso all'Evento;
                    </li>
                    <li>
                        "Token" si riferisce ad un gettone della validità di 1€, non rimborsabile, valido per l'acquisto
                        in Area Food;
                    </li>
                    <li>
                        "Partecipante" si riferisce a te, la persona che prenota l’Accesso;
                    </li>
                    <li>
                        "Associazione Sabatina" si riferisce all'organizzatore dell'Evento;
                    </li>
                    <li>
                        "Sito" si riferisce al portale online tramite il quale viene effettuata la vendita dei
                        Biglietti.
                    </li>
                </ul>
                <h3 className={"pt-4 pb-2"}>2. Prenotazione degli Accessi</h3>
                <p>
                    Gli Accessi possono essere prenotati in prevendita solo tramite il Sito e sono soggetti a
                    disponibilità. La prenotazione dell’Accesso rappresenta l'accettazione delle presenti Condizioni di
                    Partecipazione.
                </p>
                <h3 className={"pt-4 pb-2"}>3. Versamento della quota contributiva</h3>
                <p>
                    La quota contributiva per partecipare agli eventi sarà quella indicata sul Sito al momento del
                    versamento.
                    Il versamento può essere effettuato tramite le modalità indicate sul
                    Sito.
                </p>
                <h3 className={"pt-4 pb-2"}>4. Validità dei Biglietti</h3>
                <p>
                    Il Biglietto è valido solo per l'Evento e la data specificata.
                </p>
                <h3 className={"pt-4 pb-2"}>5. Cancellazione o modifica dell'evento</h3>
                <p>
                    In caso di cancellazione o modifica dell'Evento, il Partecipante sarà informato tramite i dettagli
                    di contatto forniti al momento della prenotazione.
                    In caso di cancellazione dell'Evento per motivi di forza maggiore, inclusi ma non limitati a eventi
                    atmosferici, disastri naturali, pandemie, ordini del governo, l'Associazione Sabatina non sarà
                    obbligata a rimborsare la quota contributiva versata.
                </p>
                <h3 className={"pt-4 pb-2"}>6. Limitaizoni e Responsabilità</h3>
                <p>
                    L'Associazione Sabatina non sarà responsabile per qualsiasi perdita o danno subito dal Compratore a
                    meno che tale perdita o danno non sia dovuto a negligenza o inadempimento dell'Associazione
                    Sabatina.
                </p>
                <h3 className={"pt-4 pb-2"}>7. Email</h3>
                <p>
                    L'Associazione Sabatina si riserva il diritto di utilizzare la mail inserita in fase di acquisto per
                    eventuali comunicazioni future, siano esse di servizio o di informazione.
                </p>
                <h3 className={"pt-4 pb-2"}>8. Varie</h3>
                <p>
                    Le presenti Condizioni di Partecipazione sono regolate e interpretate secondo le leggi dello Stato
                    italiano. Qualsiasi controversia relativa a queste Condizioni di Partecipazione sarà sottoposta alla
                    giurisdizione esclusiva dei tribunali italiani.
                </p>
                <h3 className={"pt-4 pb-2"}>9. Contatti</h3>
                <p>
                    Per qualsiasi domanda o richiesta, potete contattare l'Associazione Sabatina attraverso i dettagli
                    di contatto forniti sul Sito.
                </p>
                <span className={"site-title-tagline text-center"}>Riepilogo Dati</span>
                <ul>
                    <li>
                        Nominativo: <strong>{data.nominativo}</strong>
                    </li>
                    <li>
                        Email: <strong>{data.email}</strong>
                    </li>
                    <li>
                        Telefono: <strong>{data.telefono}</strong>
                    </li>
                    <li>
                        Numero {data.product === PRODUCT_TOKEN ? 'Token' : 'Ticket'}: <strong>{data.qty}</strong>
                    </li>
                    <li>
                        Totale: <strong>{data.totalAmount} €</strong>
                    </li>
                </ul>
            </div>
            <div className="mt-4 d-flex flex-justify-center justify-content-between">
                <button
                    type="button"
                    className="theme-btn"
                    onClick={() => {
                        onReturn(STEP_DATA_FORM, data);
                    }}
                >Indietro
                </button>
                <button
                    type="button"
                    className="theme-btn"
                    onClick={() => {
                        onConfirm(STEP_PAYMENT, data);
                    }}
                >Conferma
                </button>
            </div>
        </div>
    );
};

export default TerminiCondizioni;
