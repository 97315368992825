import React from "react";
import {STEP_PAYMENT} from "../../const/const";
import useLanguageContext from "../../context/LanguageContext/useLanguageContext";

const OrdineErrore = ({data, onReturn, error}) => {
    const {lang} = useLanguageContext();

    switch (lang) {
        case "en":
            return (
                <ENOrdineErrore
                    data={data}
                    onReturn={onReturn}
                    error={error}
                />
            );
        default:
            return (
                <ITOrdineErrore
                    data={data}
                    onReturn={onReturn}
                    error={error}
                />
            );
    }
};

const ENOrdineErrore = ({data, onReturn, error}) => {
    return (
        <div>
            <h2 className="text-center">Ops!</h2>
            <h3 className="text-center">Please try again.</h3>
            <p className="text-center">
                If the error persist, please use our contacts.
            </p>
            <pre>{JSON.stringify(error)}</pre>
            <div className="mt-6 text-center">
                <button
                    type="button"
                    className="btn btn-secondary inverse"
                    onClick={() => {
                        onReturn(STEP_PAYMENT, data);
                    }}
                >
                    Retry
                </button>
            </div>
        </div>
    );
};

const ITOrdineErrore = ({data, onReturn, error}) => {
    return (
        <div>
            <h2 className="text-center">Ops!</h2>
            <h3 className="text-center">Per favore fai un altro tentativo</h3>
            <p>
                Se il prbolema persiste, utilizza il nostro canale di contatto.
            </p>
            <pre>{JSON.stringify(error)}</pre>
            <div className="mt-6 text-center">
                <button
                    type="button"
                    className="btn btn-secondary inverse"
                    onClick={() => {
                        onReturn(STEP_PAYMENT, data);
                    }}
                >
                    Riprova
                </button>
            </div>
        </div>
    );
};
export default OrdineErrore;
