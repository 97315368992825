import React from "react";
import {
  EVENT_DATE_STRING,
  LAST_DAY_TO_BUY,
  STEP_DATA_FORM
} from "../../const/const";
import useLanguageContext from "../../context/LanguageContext/useLanguageContext";

const OrdineAnnullato = ({ data, onReturn, details }) => {
  const { lang } = useLanguageContext();

  switch (lang) {
    case "en":
      return (
        <ENOrdineAnnullato
          data={data}
          onReturn={onReturn}
          details={details}
        />
      );
    default:
      return (
        <ITOrdineAnnullato
          data={data}
          onReturn={onReturn}
          details={details}
        />
      );
  }
};

const ENOrdineAnnullato = ({ data, onReturn, details }) => {
  return (
    <div>
      <h2 className="text-center">Order cancelled!</h2>
      <h3 className="text-center">Any problems?</h3>
      <p className="text-center">
        The online purchase will be possible until {EVENT_DATE_STRING("en")}
        <br />
        For any information or assistance, use our contacts.
      </p>
      <div className="mt-5 text-center">
        <button
          type="button"
          className="theme-btn"
          onClick={() => {
            onReturn(STEP_DATA_FORM, data);
          }}
        >
          Retry
        </button>
      </div>
    </div>
  );
};

const ITOrdineAnnullato = ({ data, onReturn, details }) => {
  return (
    <div>
      <h2 className="text-center">Ordine annullato!</h2>
      <h3 className="text-center">Riscontri dei problemi?</h3>
      <p className="text-center">
        L'acquisto on-line rimarrà attivo fino al {LAST_DAY_TO_BUY}.<br />
        Per qualsiasi informazione o problema, utilizza il nostro canale di contatto.
      </p>

      <div className="mt-5 text-center">
        <button
          type="button"
          className="theme-btn"
          onClick={() => {
            onReturn(STEP_DATA_FORM, data);
          }}
        >
          Riprova
        </button>
      </div>
    </div>
  );
};

export default OrdineAnnullato;
